const heb = () => {
    return {
        image_errors: {
            6000: "לא ניתן לנתח תמונת פנים",
            6001: "לא הצלחנו לזהות פרצוף בתמונה",
            6002: "זיהינו יותר מפרצוף אחד בתמונה",
            6003: "זיהינו פנים חסומות",
            6004: "זיהינו שהעיניים סגורות",
            6005: "זיהינו בתמונה משקפי שמש",
            6006: "זיהינו שהפרצוף בתמונה מוטה הצידה או למעלה",
            6007: "זיהינו פנים קטנות מדי",
        },
        image_error_labels: {
            6000: "לא ניתן לנתח תמונת פנים",
            6001: "תמונה ללא פנים",
            6002: "כמה פרצופים",
            6003: "פנים מכוסות",
            6004: "עיניים עצומות",
            6005: "זיהינו בתמונה משקפי שמש",
            6006: "ראש מוטה מעלה",
            6007: "תמונה מרוחקת",
            "normal": "צילום ישר וברור"
        },
        arias: {
            stepper: {
                start: 'הינך נמצא בשלב',
                end: 'מתוך'
            },
            back_button: 'אחורה',
            cover_selection: 'כריכה מספר',
            image_to_load: 'העלאת תמונה',
            image_load: 'מעלה תמונה',
            loaded_image: 'תמונה שהועלתה',
            customer_image: "תמונת הלקוח",
            exampleDialogTitle: 'דוגמא של תמונות',
            payDialogTitle: 'יש לבחור סוג ספר',
            templateInfoDialogTitle: 'מידע על הטמפליט',
            next: 'קודם',
            prev: 'הצג אפשרויות נוספות',
            choose_template: "רשימה של נושאים",
            close: "סגור",
            more_info_about_template: "הצג מידע נוסף לנושא",
            levels:{
                level: "והנך צריך",
                upload_image: "לעלות תמונה",
                fill_info: "למלא נתונים רלוונטיים עבור הלקוח",
                choose_appearance: "לבחור מראה",
                choose_template: "לבחור תבנית",
            },
            book_image_preview: "תמונת עמוד",
            preview_book:{
                cover: "בחר תמונת כריכה",
                image: "דוגמה לדף תמונה",
                text: "דוגמה לדף טקסט",
                mini_image: "דוגמה לתמונה מספר"
            },
            name_validation: 'אימות שם'
        },
        please_upload_image: "נא להעלות תמונה",
        general: {
            next: 'ממשיכים',
            pageTitle: 'יצירת ספר',
            try_again: "נסה שוב"
        },
        error: {
            error_upload: 'עלייך לבחור תמונה כדי להמשיך',
            error_stage: 'עלייך למלא את כל הפרטים כדי להמשיך',
            error_creation: 'הייתה בעיה ביצירת הספר..',
            error_template: 'עלייך לבחור טמפלט לפני שאתה ממשיך ליצירת הספר!',
            many_faces: "היי, זיהינו יותר מפרצוף אחד בתמונה, נסו שוב"
        },
        stage1: {
            image_is_here: "פה מעלים תמונה",
            image_desc_1: "כדי להעלות תמונה, גררו אותה לתוך",
            image_desc_2: "המסך או לחצו על הכפתור",
            several_things: "דוגמאות לתמונה נכונה!",
            browse_files: "עיין בקבצים",
            or: "או",
            several_things_cont: {
                "first": "העלו תמונה איכותית של הילד/ה בה הם נמצאים במרכז",
                "second": "2. דוגמאות לתמונה נכונה"
            },
            title: 'יאללה, מתחילים!',
            explain: 'העלו תמונה איכותית של הילד בה הוא נמצא במרכז הפריים...\n' +
                '         כדי להעלות תמונה, גררו אותה לתוך המסך או לחצו על הכפתור הכתום.',
            file_size: 'מקסימום גודל:',
            note: 'תמונה איכותית וממוקדת משפרת את התוצאה - נסו לבחור תמונה ברורה שבה הדמות ניצבת במרכז ובמוקד',
            example: 'תראו לי דוגמא',
            click_here: 'לחץ/י כאן',
            popup_bullets: {
                bullet_one: 'התמונה צריכה להכיל פרצוף אחד, מומלץ ללא אלמנטים המסתירים את הפנים כמו תחפושת, מסיכה או משקפיים כהות',
                bullet_two: 'הפנים חשובות מאד לתהליך, לכן השתדלו להעלות תמונה מלאה של הפנים עם הפוקוס עליהם במרכז',
                bullet_three: 'התמונה צריכה להיות עם עיניים פקוחות',
            },
            popup_exit: 'אני מבין',
            cropper_text: 'מקמו את פני ילדכם במסגרת לתוצאות הטובות ביותר',
        },
        stage2: {
            nice_photo: 'וואו! תמונה מעולה!',
            continue_text: 'ספרו לנו על הילד/ה שלכם',
            name_validation_1: "רק מוודאים האם השם הפרטי הוא",
            name_validation_2_male: "?",
            name_validation_2_female: "?",
            yes: 'כן',
            no: 'לא',
            fields: {
                age_hero: 'גיל',
                name_male: 'שם הילד (פרטי בלבד)',
                name_female: 'שם הילדה (פרטי בלבד)',
                gender: {
                    male: 'בן',
                    female: 'בת'
                },
                age: 'הגיל שלך הוא:',
                apply: 'שמור',
                email: 'מייל',
                phone: 'מספר טלפון',
                email_validation: 'מייל לא תקין',
                sex: 'מין',
                age_validation: 'טווח הגילאים הינו',
            },
            terms: "אני מאשר/ת את ",
            terms_part_2: "תנאי השימוש והצהרת הפרטיות",
            terms_link: "https://mebook.ai/%d7%9e%d7%93%d7%99%d7%a0%d7%99%d7%95%d7%aa-%d7%a8%d7%9b%d7%99%d7%a9%d7%95%d7%aa-%d7%95%d7%94%d7%97%d7%96%d7%a8%d7%95%d7%aa/",
            terms_sms: "אני מאשר/ת לשלוח דיוור באמצעות הודעות טקסט ו/או דואר אלקטרוני",
        },
        stage3: {
            kids_name_male: 'שם הגיבור שלנו הוא: ',
            kids_name_female: 'שם הגיבורה שלנו הוא: ',
            kids_age: 'והוא בן: ',
            kids_age_female: 'והיא בת: ',
            hairColor: 'צבע שיער',
            eyeColor: 'צבע עיניים',
            skinColor: 'צבע עור',
            hairStyle: 'סגנון שיער'
        },
        stage4: {
            title: 'בוחרים נושא',
            buttonText: 'לוחצים והקסם קורה',
        },
        loading_texts: {
            text1: 'ששש….מפיקים ספר פה .תמתינו כמה רגעים ואל תסגרו את העמוד',
            subtext1: 'רק כמה רגעים קוסמיים ואנחנו שם',
            text2: 'הכנסנו את הספר לתנור',
            subtext2: 'ההרפתקה האישית שלך בדרך..',
            text3: 'יוצרים את הסיפור שלך',
            subtext3: 'הספר שלך בדרך אלייך!',
            text4: 'מפזרים אבקת קסמים',
            subtext4: 'ההרפתקה האישית שלך בדרך..',
            text5: 'בקרוב תקבלו ספר מופלא',
            subtext5: 'אנחנו מכינים סיפור רק בשבילך!',
        },
        click_me: 'לחץ עלי',
        end_process: {
            main_text: 'מדהים! בחר/י את הכריכה!',
            buttonText: 'למעבר לתשלום',
            choose_cover_pdf: 'ספר דיגיטלי',
            choose_cover_hard: 'ספר מודפס',
            p_description_hard: 'ספר מודפס בכריכה קשה והדפסה איכותית, שידהים את כל מי שיקבל אותו!',
            p_description_pdf: 'ספר אלקטרוני דיגיטלי באיכות גבוהה בפורמט PDF, מעוצב להפליא וניתן לקריאה מכל מקום ובכל זמן.',
            price_pdf: '25 ש״ח',
            price_book: '127 ש״ח + משלוח'
        },
        stage5: {
            banned_title: "על מנת להבטיח את זמינות השירות לכלל המשתמשים, ישנה מגבלה על כמות יצירת ספרים באופן חלקי.",
            desc: "על מנת להמנע ממגבלה זו, תוכלו להמשיך את תהליך יצירת אחד הספרים שכבר התחלתם בעבר."
        },
        example_of_first_page: '* דוגמה לעמוד ראשון',
        do_not_refresh_the_page: 'נא לא לרענן את העמוד',
        continue_editing_book: 'להמשך יצירה הספר, יש לבחור את אחת האופציות הבאות',
        choose: 'בחירה',
        pdf_image: 'https://mebook.ai/wp-content/uploads/2023/09/%D7%9B%D7%A8%D7%99%D7%9B%D7%94-%D7%A2%D7%91%D7%A8%D7%99%D7%AA-e1694077951904-removebg-preview-1.png',
        hard_image: 'https://mebook.ai/wp-content/uploads/2023/09/%D7%97%D7%A0%D7%95%D7%9B%D7%94-%D7%A2%D7%91%D7%A8%D7%99%D7%AA-1024x879.png',
        past_books: 'ספרים שלא הושלמו',
        terms_modal_h: "הסכם זה מתאר את התנאים וההגבלות המסדירים רכישות שבוצעו דרך האתר והאפליקציות של MeBook. זה גם מספק פרטים לגבי מדיניות ההחזרות וההחזרים שלנו.",
        terms_modal: [
            {
                title: "ביצוע הזמנות",
                p: "ניתן לבצע הזמנות של ספרי ילדים מותאמים אישית דרך האתר שלנו או אפליקציות מיועדות. כל ההזמנות מחייבות שליחת פרטים על ילדך יחד עם פרטי תשלום וכתובת משלוח. על ידי ביצוע הזמנה, אתה מאשר שכל המידע שנמסר הוא מדויק."
            },
            {
                title: "תמחור ותשלום",
                p: "המחירים המוצגים כוללים את כל המיסים וההיטלים הרלוונטיים. התשלום חייב להתבצע במלואו בעת הרכישה באמצעות כרטיס אשראי או כל אפשרות תשלום אחרת העומדת לרשותך. MeBook שומרת לעצמה את הזכות לעדכן מחירים בכל עת."
            },
            {
                title: "אישור ועיבוד הזמנה",
                p: "מייל אישור הזמנה עם פרטי הרכישה ישלח עם סיום ביצוע התשלום. יצירת והדפסת הספר יתחילו ברגע שהתשלום יעובד בהצלחה. זמני אספקה משוערים יפורסמו באישור ההזמנה."
            },
            {
                title: "הובלה ומשלוח",
                p: "אנו נשלח את הספר שלך לכתובת שצוינה באתר. זמני אספקה עשויים להשתנות בהתאם למיקומך. MeBook לא יכולה לשאת באחריות לעיכובים שנגרמו על ידי שותפי אספקה של צד שלישי."
            },
            {
                title: "החזרות וזיכויים",
                p: "אם הספר שלך נפגם במהלך המשלוח או שונה באופן משמעותי מהתצוגה המקדימה שסופקה, אנא צור איתנו קשר תוך 7 ימים מהמסירה כדי ליזום ביצוע החזר. אנו נעריך את הנזק או הפער הנטען, אנחנו עשויים לבקש ראיות מצולמות." +
                    "אם יאושר, תקבל החזר על מלוא מחיר הרכישה לאחר שהספר יוחזר למשרדים שלנו. עלויות משלוח חזרה יוחזרו אם סיבת ההחזרה נובעת מנזק או טעויות מצידנו."
            },
            {
                title: "ביטול או שינוי הזמנות",
                p: "בהתחשב באופי האישי של הספרים שלנו, לא ניתן לבטל או לשנות הזמנות לאחר תחילת יצירת הספר. אנא עיין בקפידה בפרטי ההזמנה לפני ביצוע ההזמנה."
            },
            {
                title: "הגבלת אחריות",
                p: "MeBook לא תישא באחריות לכל נזק עקיף, מקרי או תוצאתי הנובע מרכישה או שימוש במוצרים שלנו. האחריות המרבית שלנו מוגבלת למחיר הרכישה של הספר."
            },
            {
                title: "צור קשר",
                p: "אם יש לך שאלות או חששות לגבי ההזמנה שלך, אנא צור איתנו קשר בכתובת info@mebook.ai. אנו מתחייבים לחוויית הזמנה מהנה וקלה ולשביעות רצונך המלא."
            }
        ],
        terms_modal_h2: "כיצד אנו משתמשים במידע:",
        terms_modal2:[
            {p: "1. ליצור ולספק את הספר המותאם אישית שאתה רוכש דרך השירותים שלנו."},
            {p: "2. לעבד עסקאות ולשלוח לך אישורי הזמנה ומשלוח."},
            {p: "3. להגיב לפניות שלך ולספק שירות לקוחות."},
            {p: "4. לשפר ולייעל את האתר והשירותים שלנו בהתבסס על דפוסי שימוש שלך באתר."},
            {p: "5. לשלוח הודעות ווטסאפ עבור התקדמות בתהליך יצירת הספר (המשך עריכה, תזכורות עבור רכישה של ספר שלא הושלם, תזכורות עבור ספר ששולם ולא נשלח להדפסה)"},
        ],
        preebook_title: "הסיפורים שלכם:",
        preebook_subTitle:"רק הספרים שתבחרו להשלים יכנסו להזמנה"
    };
};

export default heb;
