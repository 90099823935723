import "./styles/normalize.css";
import "./styles/animation.css";
import "react-tooltip/dist/react-tooltip.css";
import "react-mobile-cropper/dist/style.css";
import Stages from "./stages/Stages";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { MainContextProvider } from "./ContextState";
import Wrapper from "./Wrapper";
import Resizer from "./common/Resizer";


function App() {
    return (
        <MainContextProvider>
            <div>
                <Router>
                    <Routes>
                        <Route element={ <Resizer><Wrapper/></Resizer> }>
                            <Route path="/" element={ <Stages/> }/>
                        </Route>
                    </Routes>
                </Router>
            </div>
        </MainContextProvider>
    );
}

export default App;
