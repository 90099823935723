import React, { useRef, useState } from "react";
import styled from "styled-components";
import Modal from "../../common/Modal";
import exit from "../images/exit.svg";

const WrapperStyled = styled.div`
    position: absolute;
    margin: 10px auto;
    bottom: -61px;
    width: 100%;
`;

const HaChupchikStyled = styled.div`
    cursor: pointer;
`;

const ContainerStyled = styled.div`

`;

const TitleStyled = styled.div`
    font-size: 14px;
`;


const BooksStyled = styled.div`
    &:hover {
        .me-pook {
            margin-inline-start: -7px;
            transition: margin-inline-start 0.5s ease;
        }
    }
`;

const BookStyled = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-inline-start: -10px;
    transition: margin-inline-start 0.5s ease;

    img {
        width: 25px;
        border-radius: 50%;
        //border: 3px solid #FFA927;
        border: 3px solid #fff;
    }
`;

const ModalListStyled = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    position: relative;

    overflow: auto;
    height: 70dvh;
    max-height: 530px;

    @media (max-width: 1130px) {
        width: 100%;
    }

    @media (max-width: 460px) {
        padding: 0 10px;
        width: 100%;
        box-sizing: border-box;

        a {
            width: 100%;
            display: grid;

            img {
                width: 100%;
            }
        }
    }
`;

const ModalItemStyled = styled.div`
    cursor: pointer;
`;

const ModalItemTitleStyled = styled.div`

`;

const ModalItemContStyled = styled.div`
    position: relative;
`;

const ModalItemContTitleStyled = styled.div`
    margin-bottom: 9px;

    h3, h4 {
        padding: 0;
        margin: 5px 0;
        font-size: 20px;
    }

    h4 {
        font-size: 18px;
    }

    hr {
        width: 70%;
    }
`;

const RemoveStyled = styled.div`
    position: absolute;
    right: 11px;
    top: -5px;
    background: #FFA927;
    border-radius: 50%;
    width: 22px;
    height: 22px;

    @media (max-width: 460px) {
        right: -8px;
        top: -5px;
        display: grid;
        align-items: center;
        justify-items: center;
    }

    img {
        width: 15px;
        height: 15px;
        margin: 3px;
    }


`;


const RecentPrebooks = ({ items, lang, langData, removePrebook }) => {
    const exampleModalRef = useRef(null);
    const [openSelection, setOpened] = useState(false);

    const openSelectionFunc = () => {
        setOpened(!openSelection);
        document.body.classList.remove("modal-active");
    };

    const items6 = items.slice(0, 6);

    return (
        <WrapperStyled>
            <HaChupchikStyled>
                <ContainerStyled
                    onClick={ openSelectionFunc }
                >
                    <TitleStyled>
                        { langData.past_books }
                    </TitleStyled>
                    <BooksStyled>
                        { items6.map((item) => {
                            const image = item?.bookPagesData?.[0]?.images?.[0];


                            return (
                                <BookStyled
                                    className={ "me-pook" }
                                    key={ item._id }
                                >
                                    <img src={ `${ image }?width=150` } alt=""/>
                                </BookStyled>
                            );
                        }) }
                    </BooksStyled>
                </ContainerStyled>
            </HaChupchikStyled>

            { openSelection && (
                <Modal
                    clickRef={ exampleModalRef }
                    role="alertdialog"
                    isOpen={ true }
                    onClose={ openSelectionFunc }
                    lang={ lang }
                    backGroundClose={ true }
                    ariaTitle={ langData.arias.exampleDialogTitle }
                >
                    <div className="popup_container preebooks-modal">
                        <ModalItemContTitleStyled>
                            <h3>{langData.preebook_title}</h3>
                            <h4>{langData.preebook_subTitle}</h4>
                            <hr/>
                        </ModalItemContTitleStyled>
                        <ModalListStyled>
                            { items.map((item) => {
                                const image = item?.bookPagesData?.[0]?.images?.[0];
                                const lang = item.template?.languageKey || "he";
                                return (
                                    <a key={ item._id } href={ `https://editor.mebook.ai?bookId=${ item._id }&token=null&language=${ lang }` }
                                       target="_blank" rel="noopener noreferrer">
                                        <ModalItemStyled>
                                            <ModalItemTitleStyled>
                                                { item.title }
                                            </ModalItemTitleStyled>
                                            <ModalItemContStyled>
                                                <RemoveStyled
                                                    onClick={ (e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        removePrebook(item._id);
                                                    } }
                                                >
                                                    <img src={ exit } alt="exit"/>
                                                </RemoveStyled>
                                                <img src={ `${ image }?width=150` } alt=""/>
                                            </ModalItemContStyled>
                                        </ModalItemStyled>
                                    </a>
                                );
                            }) }
                        </ModalListStyled>
                    </div>
                </Modal>
            ) }
        </WrapperStyled>
    );
};

export default RecentPrebooks;
