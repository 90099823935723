const danish = () => {
    return {
        image_errors: {
            6000: "Kunne ikke analysere ansigtsbillede",
            6001: "Billedet indeholder ingen ansigter",
            6002: "Billedet indeholder mere end ét ansigt",
            6003: "Ansigtet er tilsløret",
            6004: "Øjnene er lukkede",
            6005: "Bærer solbriller",
            6006: "Ansigtet er vippet",
            6007: "Ansigtet er for lille",
        },
        image_error_labels: {
            6000: "Kunne ikke analysere ansigtsbillede",
            6001: "Intet ansigt",
            6002: "Kun ét barn",
            6003: "Ansigtet er tilsløret",
            6004: "Øjnene er lukkede",
            6005: "Bærer solbriller",
            6006: "Lige-på syn",
            6007: "Ansigtet er for lille",
            "normal": "Klart og godt belyst"
        },
        arias: {
            stepper: {
                start: "Du er på trin",
                end: "af"
            },
            back_button: "Tilbage",
            cover_selection: "Omslag nummer",
            image_to_load: "Upload billede",
            image_load: "Uploader billede",
            loaded_image: "Billede uploadet",
            exampleDialogTitle: "Billedeksempel",
            next: "Næste",
            prev: "Forrige",
            fill_requironments: "",
            name_validation: "Navnevalidering"
        },
        please_upload_image: "Venligst upload billedet",
        general: {
            next: "Næste",
            pageTitle: "Bogoprettelse",
            try_again: "Prøv igen"
        },
        error: {
            error_upload: "Der var et problem med at opdatere billedet",
            many_faces: "Venligst upload et billede med kun ét ansigt",
        },
        stage1: {
            image_is_here: "Upload et billede her",
            image_desc_1: "Upload et klart billede af dit barn for at gøre",
            image_desc_2: " dem til stjernen i deres egen historie!",
            several_things: "Tips til det perfekte heltebillede:",
            browse_files: "Gennemse filer",
            several_things_cont: {
                "first": "Upload et billede af høj kvalitet af barnet, hvor de er i centrum",
                "second": "2. Eksempler på et korrekt billede"
            },
            title: "Start dit barns eventyr!",
            explain: "Upload først billedet, du kan trække eller klikke på knappen",
            file_size: "Maks størrelse:",
            note: "Et billede i bedre kvalitet og med den rette vinkel vil give et bedre resultat",
            example: "Vis mig et eksempel",
            popup_bullets: {
                bullet_one: "Billedet skal have ét ansigt, uden briller, parykker, kostumer osv.",
                bullet_two: "Billedet skal tages med ansigtet foran kameraet i en vinkel, hvor hele ansigtet kan ses",
                bullet_three: "Billedet skal være med åbne øjne",
            },
            popup_exit: "Jeg forstår",
            cropper_text: "Centrer dit barns ansigt i rammen for de bedste resultater"
        },
        stage2: {
            nice_photo: "Åh! Dette er et flot billede!",
            continue_text: "Lad os personliggøre dit barns eventyr!",
            name_validation_1: "Er",
            name_validation_2_male: "barnets fornavn?",
            name_validation_2_female: "barnets fornavn?",
            yes: "Ja",
            no: "Nej",
            fields: {
                name_male: "Barnets navn",
                name_female: "Barnets navn",
                age_hero: "Alder",
                gender: {
                    male: "Dreng",
                    female: "Pige"
                },
                age: "Alder:",
                apply: "Gem",
                email: "Email",
                phone: "Telefonnummer",
                email_validation: "Forkert email-format",
                sex: "Køn",
                age_validation: "Alder skal være",
            },
            terms: "Jeg accepterer story magic ",
            terms_part_2: "(vilkår)",
            terms_link: "https://mebook.ai/en/refund_returns/",
            terms_sms: "Ved at klikke på 'Gem' accepterer du vores vilkår",
        },
        stage3: {
            kids_name_male: "Vores helts navn: ",
            kids_name_female: "Vores heltindes navn er: ",
            kids_age_female: "Og hun er: ",
            kids_age: "Og han er: ",
            hairColor: "Hårfarve",
            eyeColor: "Øjenfarve",
            skinColor: "Hudfarve",
            hairStyle: "Frisure"
        },
        stage4: {
            title: "Vælg din historie",
            buttonText: "Lad magien begynde",
        },
        loading_texts: {
            text1: "Gnistrende fantasi...",
            subtext1: "Kun få magiske øjeblikke væk!",
            text2: "Frigiv kreativitet...",
            subtext2: "Dit personlige eventyr brygges!",
            text3: "Omfavn forventningen...",
            subtext3: "Din AI-bog er undervejs!",
            text4: "Fordyb dig i forundring...",
            subtext4: "Dit personlige eventyr brygges!",
            text5: "Fængslende øjeblikke foran...",
            subtext5: "Vi skaber en historie specielt til dig!",
        },
        click_me: "Klik på mig",
        end_process: {
            main_text: "Fantastisk! Vælg en af de muligheder, du vil \n" +
                "bruge til dit bogomslag",
            buttonText: "Gå til kassen",
            choose_cover_pdf: "Digital e-bog",
            choose_cover_hard: "Hardcover-bog",
            p_description_pdf: "En høj-kvalitets digital e-bog i PDF-format, smukt designet og læsbar fra hvor som helst og når som helst.",
            p_description_hard: "Trykt bog med hardcover og tryk i høj kvalitet, med et moderne design",
            price_pdf: "$15",
            price_book: "$39"
        },
        stage5: {
            banned_title: "Vi er dedikerede til at sikre, at denne enestående service er tilgængelig for alle vores kunder. Som led i denne indsats har vi indført en begrænsning på bogoprettelse til tre gange dagligt.",
            desc: "Du har dog stadig mulighed for at vælge fra tidligere genererede bøger for din bekvemmelighed."
        },
        do_not_refresh_the_page: "Venligst opdater ikke siden",
        example_of_first_page: "* Eksempel på første side",
        continue_editing_book: "For at fortsætte med at redigere bogen, vælg en af følgende muligheder",
        choose: "Vælg",
        pdf_image: "https://mebook.ai/wp-content/uploads/2023/09/U5XQa1yO5-1-e1694077013211-1024x1021.png",
        hard_image: "https://mebook.ai/wp-content/uploads/2023/09/%D7%98%D7%91%D7%90%D7%9C%D7%98-1.png",
        past_books: "Tidligere bøger",
        terms_modal_h: "Denne aftale beskriver vilkårene og betingelserne for køb foretaget via MeBooks websted og mobilapplikationer. Det giver også oplysninger om vores retur- og refunderingspolitik.",
        terms_modal: [
            {
                title: "Afgivelse af ordre",
                p: "Du kan afgive ordrer på personlige børnebøger gennem vores hjemmeside eller mobilapps. Alle ordrer kræver indsendelse af oplysninger om dit barn samt betalingsoplysninger. Ved at afgive en ordre bekræfter du, at alle oplysninger, der er indsendt, er korrekte."
            },
            {
                title: "Priser og betaling",
                p: "De viste priser inkluderer alle gældende skatter og afgifter. Betaling skal ske fuldt ud på købstidspunktet via kreditkort eller en anden betalingsmulighed, der stilles til rådighed. MeBook forbeholder sig ret til at opdatere priser til enhver tid."
            },
            {
                title: "Ordrebekræftelse og behandling",
                p: "Du vil modtage en ordrebekræftelse via e-mail med oplysninger om dit køb. Oprettelsen og trykningen af bogen begynder, så snart betalingen er gennemført. Forventede leveringstider vil blive meddelt i ordrebekræftelsen."
            },
            {
                title: "Forsendelse og levering",
                p: "Vi vil sende din færdige bog til den adresse, der er angivet ved betaling. Leveringstider kan variere afhængigt af din placering. MeBook kan ikke holdes ansvarlig for forsinkelser forårsaget af tredjeparts leveringspartnere."
            },
            {
                title: "Returneringer og refusioner",
                p: "Hvis din bog er beskadiget under forsendelsen eller adskiller sig væsentligt fra den forudgående visning, bedes du kontakte os inden for 7 dage efter levering for at påbegynde en returnering. Vi vil vurdere den anmeldte skade eller afvigelse og kan anmode om fotografisk dokumentation." +
                    "Hvis godkendt, vil du blive refunderet det fulde købsbeløb, når bogen er returneret til vores opfyldelsescenter. Returforsendelsesomkostninger refunderes, hvis årsagen til returneringen skyldes skader eller fejl fra vores side."
            },
            {
                title: "Annullering eller ændring af ordrer",
                p: "På grund af bøgernes personlige karakter kan ordrer ikke annulleres eller ændres, når bogoprettelsen er påbegyndt. Gennemse venligst ordredetaljerne omhyggeligt, før du afgiver din ordre."
            },
            {
                title: "Ansvarsbegrænsning",
                p: "MeBook vil ikke være ansvarlig for indirekte, tilfældige eller følgeskader som følge af køb eller brug af vores produkter. Vores maksimale ansvar er begrænset til købsprisen på bogen."
            },
            {
                title: "Kontakt os",
                p: "Hvis du har spørgsmål eller bekymringer vedrørende din ordre, bedes du kontakte os på info@mebook.ai. Vi er engagerede i at give en sjov, nem bestillingsoplevelse og din fulde tilfredshed."
            }
        ],
        preebook_title: "Dine bøger",
        preebook_subTitle: "Bøger nedenfor er ikke i indkøbskurven",
    };
};

export default danish;
